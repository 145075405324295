import { Link, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenu, NavbarMenuItem, NavbarMenuToggle } from '@nextui-org/react'
import AvatarUserMenu from 'components/organisms/Menu/AvatarUserMenu'
import { useState } from 'react'
import { GiRamProfile } from 'react-icons/gi'
import { useLocation } from 'react-router-dom'

interface MenuItem {
    key: string
    isActive: boolean
    routePath: string
    name: string
}

const Header = () => {
    const location = useLocation()

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

    const isHomePage = location.pathname === '/home' || location.pathname === '/'
    const isAboutMePage = location.pathname === '/about-me'
    const isSkillsResumePage = location.pathname === '/skills-resume'
    const isPrivatePage = location.pathname === '/private'

    const menuItems: MenuItem[] = [
        {
            key: 'home',
            isActive: isHomePage,
            routePath: '/home',
            name: 'Home',
        },
        {
            key: 'aboute-me',
            isActive: isAboutMePage,
            routePath: '/about-me',
            name: 'Vorstellung',
        },
        { key: 'skills-resume', isActive: isSkillsResumePage, routePath: '/skills-resume', name: 'Skills / Resume' },
        { key: 'private', isActive: isPrivatePage, routePath: '/private', name: 'Private' },
    ]

    const getLinkColor = (isPageSelected: boolean) => (isPageSelected ? 'secondary' : 'foreground')

    return (
        <Navbar isBordered onMenuOpenChange={setIsMenuOpen} isMenuOpen={isMenuOpen}>
            <NavbarContent>
                <NavbarMenuToggle aria-label={isMenuOpen ? 'Menü schließen' : 'Menü öffnen'} className="sm:hidden" />

                <NavbarBrand className="gap-4">
                    <div className="text-4xl dark:text-cyan-600">
                        <GiRamProfile />
                    </div>

                    <p className="font-bold text-inherit">Portfolio</p>
                </NavbarBrand>
            </NavbarContent>

            <NavbarContent className="hidden sm:flex gap-4" justify="center">
                {menuItems.map(item => (
                    <NavbarItem key={item.key} isActive={item.isActive}>
                        <Link color={getLinkColor(item.isActive)} href={item.routePath}>
                            {item.name}
                        </Link>
                    </NavbarItem>
                ))}
            </NavbarContent>

            <NavbarContent as="div" justify="end">
                <AvatarUserMenu />
            </NavbarContent>

            <NavbarMenu>
                {menuItems.map((item, index) => (
                    <NavbarMenuItem key={`menu-${item.key}-${index}`}>
                        <Link
                            className="w-full"
                            color={getLinkColor(item.isActive)}
                            href={item.routePath}
                            onClick={() => setIsMenuOpen(false)}>
                            {item.name}
                        </Link>
                    </NavbarMenuItem>
                ))}
            </NavbarMenu>
        </Navbar>
    )
}

export default Header
