import { subYears } from 'date-fns'
import { intervalToDuration } from 'date-fns/intervalToDuration'

const experienceSince = new Date(2013, 5, 1)
const persoanlBirthday = new Date(1992, 0, 0)
const rabbitBirthday = new Date(2020, 10, 0)

const getDateDiffFromNow = (subDate: Date): Date => {
    const now = new Date()
    return subYears(new Date(now.getTime() - subDate.getTime()), 1970)
}

export const getItExperienceFormatted = (): string => {
    const now = new Date()
    const diffDuration = intervalToDuration({ start: experienceSince, end: now })

    return `${diffDuration.years} Jahre ${diffDuration.months ?? 0} Monate ${diffDuration.days ?? 0} Tage`
}

export const getYearsOfPersonalAge = (): number => getDateDiffFromNow(persoanlBirthday).getFullYear()

export const getYearsOfRabbit = (): number => getDateDiffFromNow(rabbitBirthday).getFullYear()
