import { Timeline } from './timeline'

export const timelineEducations: Timeline[] = [
    {
        title: 'Ausbildung zum Fachinformatiker in Anwendungsentwicklung',
        descirption:
            'Bei der van Clewe validData GmbH konnte ich meine Ausbildung zum Fachinformatiker erfolgreich abschließen. Schon in meiner Ausbildung konnte ich meine ersten Kunden selbständig betreuen',
        start: new Date(2010, 7),
        end: new Date(2013, 6),
    },
    {
        title: 'Fachabitur Schwerpunkt Technik Informations-Kommunikationstechnik',
        descirption:
            'In dem Fachabitur konnte ich mein Interesse in der IT vertiefen. Zudem konnte ich im Bereich der Elektrotechnik interessantes für mein Leben mitnehemn',
        start: new Date(2008, 7),
        end: new Date(2010, 7),
    },
    {
        title: 'Realschulabschluss',
        descirption:
            'In der mittleren Reife hatte ich meinen ersten Kontakt zur Entwicklung von Modulen (Hier ein Mindstorm-Roboter). Dies weckte mein Interesse, wobei ich auch im privaten Umfeld anfing C++ zu lernen',
        start: new Date(2003, 7),
        end: new Date(2008, 7),
    },
]

export const timelineProjects: Timeline[] = [
    {
        title: 'Digital Twin (Erneuerbare)',
        descirption:
            'Es wird ein digitales Twin der Anlagen zur Erzeugung erneuerbarer Energie in der Azure-Cloud umgesetzt. Entwicklung in React (TS) & C#.NET Core. Infrastruktur über IaC (ARM & Bicep) - CI/CD in AzureDev Ops über YAML-Pipelines',
        start: new Date(2023, 4),
        end: new Date(2024, 6),
    },
    {
        title: 'Smart-Meter Infrastructure Integration',
        descirption:
            'Im Energie-Sektor wurde eine komplette Infrastruktur für die Erfassung & Auswertung von intelligenten Smart-Meters entstehen. Entwicklung in Angular (TS) & C#.NET Core. Die Infrasturktur wurde über AKS & Docker-Container realisiert',
        start: new Date(2021, 3),
        end: new Date(2023, 4),
    },
    {
        title: 'Automatisierung aerodynamischer Zugberechnungs-Prozesse',
        descirption:
            'Entwicklung einer in STAR-CCM+ integrierten UI zur Verbesserung der Kernprozesse der Aerodynamik-Abteilung. Integration von Algorithmen zur Validierung, Automatisierung und Erstellung von Standard-Randbedingungen in Java EE',
        start: new Date(2019, 3),
        markedTimelineTitle: 'Aktiv',
    },
]
