import { BeanBedImage } from 'images/beamBedImage'
import { ComplexityImage } from 'images/complexityImage'
import { GitlabImage } from 'images/gitlabImage'
import { IonicCryptoAppImage } from 'images/ionicCryptoAppImage'
import { NatureImage } from 'images/natureImage'
import { NerdImage } from 'images/nerdImage'
import { OrganizedTeamImage } from 'images/organizedTeamImage'
import { PacmanImage } from 'images/pacmanImage'
import { PerfectionImage } from 'images/perfectionImage'
import { PhotographyImage } from 'images/photographyImage'
import { PizzaMakerImage } from 'images/pizzaMakerImage'
import { RabbitImage } from 'images/rabbitImage'
import { getYearsOfRabbit } from 'utils/dateHelper'

import { PrivateDetail } from './privateDetail'

export const privateDetails: PrivateDetail[] = [
    {
        textFooter: 'Eine Priese Perfektion',
        textSubFooter: 'Ich liebe ein Hauch von Perfektion bei meiner Arbeit, sowie auch Privat',
        imageBase64: PerfectionImage,
    },
    {
        textFooter: 'Bugs / Optmierungen / Clean Code',
        textSubFooter: 'Fokus & Offenheit - wie bei Scrum sollte zu den täglichen Prozess gehören',
        imageBase64: PacmanImage,
    },
    {
        textFooter: 'Leidenschaft der Fotografie',
        textSubFooter: 'Portrait / Hochzeit / Events / Landschaft',
        imageBase64: PhotographyImage,
    },
    {
        textFooter: 'GitLab inkl. CI/CD',
        textSubFooter: 'Erstellung einer eigenen Testumgebung mit GitLab & CI/CD',
        imageBase64: GitlabImage,
    },
    {
        textFooter: 'Komplexe Prozesse - Immer gerne',
        textSubFooter: 'Komplexe fachliche / technische Prozesse begeisten mich!',
        imageBase64: ComplexityImage,
    },
    {
        textFooter: 'Selbstorganisiert / Leitende Persönlichkeit',
        textSubFooter: 'Leitung kleinerer Gruppen oder Unterstützung in allen Bereichen. Auch Meetings / Workshops',
        imageBase64: OrganizedTeamImage,
    },
    {
        textFooter: 'Enthusiasmus - auch Privat',
        textSubFooter: 'Eigenentwicklung Crypto Ionic-App (Initial)',
        imageBase64: IonicCryptoAppImage,
    },
    {
        textFooter: 'Unterwegs in der Natur',
        textSubFooter: 'Wandertouren / Familienspaziergänge',
        imageBase64: NatureImage,
    },
    {
        textFooter: 'Handwerk mit Natur',
        textSubFooter: 'Selfmade Balkenbett / Balkentisch / Raumtrenner',
        imageBase64: BeanBedImage,
    },
    {
        textFooter: 'Italienische Leidenschaft entdeckt?',
        textSubFooter: 'Selfmade neapolitanische Pizza schmeckt doch am Besten!',
        imageBase64: PizzaMakerImage,
    },
    {
        textFooter: 'Kleiner Spielpartner zur Fantasie-Anregung',
        textSubFooter: `Eine ${getYearsOfRabbit()} Jährige Tochter für After-Spielerlebnisse :)`,
        imageBase64: RabbitImage,
    },
    { textFooter: 'Etwas Nerd muss sein', textSubFooter: 'Regelmäßige D&D-Runden', imageBase64: NerdImage },
]
