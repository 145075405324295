import { Button, Link } from '@nextui-org/react'
import { HoverDampingAnimation } from 'components/atoms/Animation/HoverDampingAnimation'
import { FaSquareGitlab } from 'react-icons/fa6'
import { ImLinkedin, ImXing } from 'react-icons/im'

const PlatformContact = () => {
    return (
        <div className="lg:flex grid gap-0 lg:gap-4 mx-6 lg:mx-0 items-center">
            <Link href="https://www.xing.com/profile/Sven_Buss7">
                <HoverDampingAnimation>
                    <Button isIconOnly color="primary" aria-label="Xing">
                        <ImXing />
                    </Button>
                </HoverDampingAnimation>
            </Link>

            <Link href="https://de.linkedin.com/in/sven-buss-33335a230?trk=public_profile_browsemap">
                <HoverDampingAnimation>
                    <Button isIconOnly color="default" variant="faded" aria-label="LinkedIn">
                        <ImLinkedin />
                    </Button>
                </HoverDampingAnimation>
            </Link>

            <Link href="https://gitlab.com/busssven">
                <HoverDampingAnimation>
                    <Button isIconOnly color="default" variant="faded" aria-label="GitLab">
                        <FaSquareGitlab />
                    </Button>
                </HoverDampingAnimation>
            </Link>
        </div>
    )
}

export default PlatformContact
