import { Service } from 'models/service/service'
import { ServiceCategory } from 'models/service/serviceCategory'

export const serviceConstants: Service[] = [
    {
        name: 'Frontend',
        description: 'Hauptsächlich React & Angular with Typescript, ASP.NET',
        subDescription: 'Winforms, WPF.NET, HTML5, css, jqeury & javascript',
        category: ServiceCategory.Frontend,
        yearsOfExperience: 7,
        countOfProjects: 9,
        projects: [
            {
                name: 'Digital Twin (Erneuerbare)',
                skills: ['React with Typescript / MUI', 'Redux, hook-form, Msal, vite & eslint'],
                tasks: ['Konzeptionierung / Technische Umsetzung', 'CQRS-Pattern, Rollenberechtiung, Atomic-Design, Token / Security'],
            },
            {
                name: 'SmartMeter-Integration',
                skills: ['Angular with Typescript, HTML 5 & SCSS / MUI', 'SignalR, Msal, ngx-translate, highcharts'],
                tasks: ['Analyse & Konzepterstellung / Technische Leitung', 'WebComponent', 'Anbindung Subsysteme & Smart-Meter Geräte'],
            },
        ],
    },
    {
        name: 'Backend / Programming',
        description: 'C#.NET / Core / Java EE',
        subDescription: 'Java with Springboot, wenig Python',
        category: ServiceCategory.Backend,
        yearsOfExperience: 10,
        countOfProjects: 20,
        projects: [
            {
                name: 'Digital Twin (Erneuerbare)',
                skills: ['C# / ASP Core.NET, Python', 'Dapper, OData, CQRS'],
                tasks: [
                    'Technische Machbarkeit / Abstimmung internen Architekten',
                    'Datenbank-Modellierung / Reverse Engineering',
                    'Security (DB-Token Handling, Rollenberechtiung)',
                ],
            },
            {
                name: 'Automatisierung von aerodynamischen Zugberechnungs-Prozessen',
                skills: ['Java EE with Netbeans (Maven), TLC/TK, C++, C#', 'Linux, Git & GitLab'],
                tasks: [
                    'Technische Leitung',
                    'Analyse & Umsetzung von Anforderungen / Konzeptionierung',
                    'Entwicklung und Tests der entwickelten Module',
                    'Reverse Engineering eines Kupplungslast-Tool',
                ],
            },
        ],
    },
    {
        name: 'Azure / Cloud',
        description: 'Azure-Resourcen (wie Functions, SQL, Cosmos, PostresSQL, KV, WebApp, Principles etc.)',
        subDescription: 'Docker, AKS (Wenig mit Helm)',
        category: ServiceCategory.Cloud,
        yearsOfExperience: 4,
        countOfProjects: 3,
        projects: [
            {
                name: 'Digital Twin (Erneuerbare)',
                skills: ['Azure & DevOps / Powershell', 'Azure Function, WebApp, AppInsight, Storage, KV', 'AppService Plan, SQL & Comsos'],
                tasks: ['Fehleranlyse in Azure / AppInsight', 'Security (VNet, Restriction, Principal, KV)'],
            },
            {
                name: 'SmartMeter-Integration',
                skills: ['Azure & DevOps / Powershell', 'Docker'],
                tasks: ['Hosting der Docker-Container in Azure', 'Webhosting über Docker (Nginx)'],
            },
        ],
    },
    {
        name: 'Engineering / Methodik',
        description: 'Azure DevOps, CI/CD, IaC mit ARM & Bicep',
        subDescription: 'Scrum / Kanban, Requirement-Engineering',
        category: ServiceCategory.Engineering,
        yearsOfExperience: 3,
        countOfProjects: 2,
        projects: [
            {
                name: 'Digital Twin (Erneuerbare)',
                skills: ['Azure & DevOps', 'CI/CD / Bicep / ARM / YAML'],
                tasks: ['IaC - Bicep & ARM Deployment', 'Security (VNet, Restriction, Principal, KV)'],
            },
            {
                name: 'SmartMeter-Integration',
                skills: ['Azure & DevOps / CI/CD / YAML, Docker', 'Kubernetes (Helm), Nuke, Hangfire'],
                tasks: [
                    'Kleinere Anpassung der Pipelines',
                    'Entwicklung (Frontend + Backend)',
                    'Anbindung Subsysteme & Smart-Meter Geräte',
                    'Leitung Frontend (im kleineren Rahmen) / Support der Kollegen/innen',
                ],
            },
        ],
    },
    {
        name: 'Projekt- / Teamleitung',
        description: 'Dev-Lead im kleinerem Rahmen (1-3 Kollegen/innen), Proxy-PO',
        subDescription: 'Projektleitung (1-3 Kollegen/innen)',
        category: ServiceCategory.Management,
        yearsOfExperience: 4,
        countOfProjects: 3,
        projects: [
            {
                name: 'Automatisierung von aerodynamischen Zugberechnungs-Prozessen',
                skills: ['Technische Leitung / Projektleitung', 'JAVA', 'C#', 'Matlab'],
                tasks: [
                    'Organisation / Koordinierung',
                    'Requirement-Engineering / Rechnungs- & Angebotserstellung',
                    'Erstellung von Konzepten für den Integrationsprozess',
                    'Verbesserungsvorschläge & Abnahme einzelner Module',
                ],
            },
            {
                name: 'SmartMeter-Integration',
                skills: ['Technische Leitung', 'Anforderungsanalyse'],
                tasks: ['Leitung Frontend (im kleineren Rahmen)', 'Support der Kollegen/innen'],
            },
        ],
    },
    {
        name: 'Sonstiges',
        description: 'Matlab, TCL/TK, Powershell',
        subDescription: 'Ionic für Apps (React), Android (Java), iOS (Objective-C)',
        category: ServiceCategory.Misc,
        yearsOfExperience: 6,
        countOfProjects: 5,
        projects: [
            {
                name: 'Auswertungen von vorbedingten Prozessen',
                skills: ['Technische Leitung / Projektleitung', 'Matlab'],
                tasks: ['Organisation / Koordinierung', 'Entwurf Konzepte & Leitung der Anforderungsanalyse'],
            },
            {
                name: 'Vollwertige Zeit- & Auftragserfassung',
                skills: ['Android (JAVA)', 'Objective-C (iOS)', 'VB.NET', 'Azure Cloud'],
                tasks: ['Erstellung relevanter Module im Backend, sowie im Frontend', 'Kundenabnahme & -Test', 'Technische Umsetzung'],
            },
        ],
    },
]
