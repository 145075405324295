interface TimelineHeaderProps {
    title: string
    latestMark?: string
}

const TimelineHeader = ({ title, latestMark }: TimelineHeaderProps) => {
    return (
        <h3 className="flex items-center mb-1 text-2xl font-semibold text-gray-900 dark:text-yellow-600">
            {title}

            {latestMark && (
                <span className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ms-3">
                    {latestMark}
                </span>
            )}
        </h3>
    )
}

export default TimelineHeader
