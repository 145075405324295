import saveAs from 'file-saver'
import { ReferencesEncoded } from 'files/Referenzen'
import { ResumeEncoded } from 'files/Resume'
import { SkillmatrixEncoded } from 'files/Skillmatrix'
import JSZip from 'jszip'

export const downloadSelfInformationZipFile = () => {
    const zip = new JSZip()
    zip.file('Referenzen.pdf', ReferencesEncoded, { base64: true })
    zip.file('Resume.pdf', ResumeEncoded, { base64: true })
    zip.file('Skillmatrix.pdf', SkillmatrixEncoded, { base64: true })
    zip.generateAsync({ type: 'blob' }).then(zipFile => saveAs(zipFile, 'Unterlagen Sven Buss.zip'))
}

export const downloadSkillSet = () => {
    const zip = new JSZip()
    zip.file('Skillmatrix.pdf', SkillmatrixEncoded, { base64: true })
    zip.generateAsync({ type: 'blob' }).then(zipFile => saveAs(zipFile, 'Skillmatrix.zip'))
}
