import { SlideInTextAnimation } from 'components/atoms/Animation/SlideInTextAnimation'
import ImageZoom from 'components/atoms/Image/ImageZoom'
import Title from 'components/atoms/Typography/Title'
import { motion } from 'framer-motion'
import { privateDetails } from 'models/private/privateDetailConstant'
import { nanoid } from 'nanoid'

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.2,
            staggerChildren: 0.2,
        },
    },
}

const item = {
    hidden: { y: 40, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
    },
}

const PrivatePage = () => {
    return (
        <div className="h-auto w-auto grid px-3 mx-3 lg:px-0 lg:mx-0">
            <Title text="Hobby /" highlightedText="Leidenschaft" />
            <div className="text-center text-sm lg:text-lg italic mb-16">
                <SlideInTextAnimation
                    className="text-pretty"
                    text="Diese Bilder sind AI generiert & illustrieren einen Einblick in meine Arbeits-, bzw. Lebensweise"
                />
            </div>

            <motion.ul className="container" variants={container} initial="hidden" animate="visible">
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mb-10">
                    {privateDetails.map((detail, i) => (
                        <motion.div key={nanoid()} variants={item}>
                            <ImageZoom detail={detail} showInitialTooltip={i === 0} />
                        </motion.div>
                    ))}
                </div>
            </motion.ul>
        </div>
    )
}

export default PrivatePage
