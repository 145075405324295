import { motion } from 'framer-motion'

interface SlideInTextAnimationProps {
    text: string
    className?: string
}

export const SlideInTextAnimation = ({ text, className }: SlideInTextAnimationProps) => {
    return (
        <motion.p
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            className={className}>
            {text}
        </motion.p>
    )
}
