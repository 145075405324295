import TimelineDescription from 'components/atoms/Timeline/TimelineDescription'
import TimelineHeader from 'components/atoms/Timeline/TimelineHeader'
import TimelineSubHeaderTime from 'components/atoms/Timeline/TimelineSubHeaderTime'
import { motion } from 'framer-motion'
import { Timeline } from 'models/timeline/timeline'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { CgEditBlackPoint } from 'react-icons/cg'

interface TimelineProps {
    timelines: Timeline[]
}

const TimelineComponent = ({ timelines }: TimelineProps) => {
    const [isAnimated, setIsAnimated] = useState<boolean>(false)

    return (
        <ol className="relative border-s border-gray-200 dark:border-gray-700">
            {timelines.map(timeline => (
                <motion.div
                    key={nanoid()}
                    className="mb-10 ms-6"
                    initial={{ opacity: isAnimated ? 1 : 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ delay: 0.2, duration: 1.5 }}
                    viewport={{ once: true }}
                    onAnimationComplete={() => setIsAnimated(true)}>
                    <span
                        className={`absolute flex items-center justify-center w-6 h-6
                             bg-blue-100 rounded-full -start-3 ring-8
                             ring-white dark:ring-gray-900 dark:bg-blue-900`}>
                        <CgEditBlackPoint />
                    </span>

                    <TimelineHeader title={timeline.title} latestMark={timeline.markedTimelineTitle} />
                    <TimelineSubHeaderTime start={timeline.start} end={timeline.end} />

                    <TimelineDescription description={timeline.descirption} />
                </motion.div>
            ))}
        </ol>
    )
}

export default TimelineComponent
