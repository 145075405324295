import { Divider } from '@nextui-org/react'

const subTextClass = 'lg:max-w-screen-xl pr-2 text-center text-pretty text-xs mt-0.5'

const Footer = () => {
    return (
        <footer className="bg-white rounded-lg shadow m-4 dark:bg-gray-800">
            <div className="p-4 flex items-center justify-between">
                <div className="flex h-3 items-center space-x-2 lg:space-x-6 text-small">
                    <span className="text-xs text-center text-gray-500 dark:text-gray-400">
                        Deployed mit{' '}
                        <a href="https://gitlab.com/busssven92/portfolio-app" className="underline">
                            Gitlab
                        </a>
                    </span>

                    <Divider orientation="vertical" />
                    <a className={`hidden lg:block ${subTextClass}`}>
                        Diese Seite wurde mit React & Tailwind CSS inkl. Framer-Motion umgesetzt
                    </a>

                    <Divider orientation="vertical" className="hidden lg:block" />
                    <a className={`italic ${subTextClass}`}>Alle Profilbilder sind selbst erstellt und sind durch mich geschützt</a>
                </div>

                <div>
                    <ul className="text-pretty text-xs lg:text-sm font-medium text-gray-500 dark:text-gray-400">
                        <li>
                            <a href="mailto:buss.sven@outlook.com?subject=Interesse an einer Projektunterstützung?" className="underline">
                                Kontakt
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer
