import { Avatar, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Link, useDisclosure } from '@nextui-org/react'
import { Key } from 'react'
import { downloadSelfInformationZipFile } from 'utils/fileHelper'

import SkillSearchModal from '../modals/SkillSearchModal'

const selfKey: Key = 'self'
const skillKey: Key = 'skills'
const mailKey: Key = 'mail'
const cvKey: Key = 'cv'
const gitlabKey: Key = 'gitlab'

const AvatarUserMenu = () => {
    const skillSearchModal = useDisclosure()

    const onMenuAction = (key: Key) => {
        if (key === skillKey) {
            skillSearchModal.onOpen()
        }
    }

    return (
        <>
            <Dropdown placement="bottom-end" backdrop="blur">
                <DropdownTrigger>
                    <Avatar isBordered as="button" className="transition-transform" color="primary" name="Sven Buss" size="sm" src="" />
                </DropdownTrigger>

                <DropdownMenu aria-label="Profile Actions" variant="flat" onAction={onMenuAction} disabledKeys={[selfKey, gitlabKey]}>
                    <DropdownItem key={selfKey} textValue={selfKey} description="Fullstack Dev-Ops Engineer">
                        Sven Buss
                    </DropdownItem>

                    <DropdownItem key={skillKey} textValue={skillKey} description="Skills suchen">
                        Passende Skills für ihr Projekt
                    </DropdownItem>

                    <DropdownItem
                        key={mailKey}
                        textValue={mailKey}
                        description="buss.sven@outlook.com"
                        href="mailto:buss.sven@outlook.com?subject=Interesse an einer Projektunterstützung?">
                        Kontakt
                    </DropdownItem>
                    <DropdownItem key={cvKey} description="CV + Referenzen herunterladen" onClick={() => downloadSelfInformationZipFile()}>
                        Download
                    </DropdownItem>

                    <DropdownItem key={gitlabKey} textValue={gitlabKey} color="secondary">
                        <Link color="foreground" href="#">
                            Erstellt mit React & GitLab CI/CD
                        </Link>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>

            <SkillSearchModal disclosure={skillSearchModal} />
        </>
    )
}

export default AvatarUserMenu
