import { getItExperienceFormatted, getYearsOfPersonalAge } from 'utils/dateHelper'

import { Info } from './info'
import { InfoCategory } from './infoCategory'

export const infoConstants: Info[] = [
    {
        title: 'Etwas über mich',
        description:
            'Ich bin ein professional Fullstack-Engineer mit vielen branchenübergreifenden Erfahrungen im Frontend, sowie im Backend. Zudem kann ich merkliche Erfahrung in Azure-Cloud DevOps (IaC mit ARM / Bicep) aufweisen',
        points: [
            'Verfügbar ab 01.08.2024 (100%)',
            `${getYearsOfPersonalAge()} Jahre (Baujahr 1992)`,
            `IT-Erfahrung seit ${getItExperienceFormatted()}`,
        ],
        category: InfoCategory.Me,
    },
    {
        title: 'Fullstack- / DevOps-Engineer',
        description:
            'Unterstützung bei Infrastruktur, Azure Cloud oder DevOps? Ich konnte einige Projekte bereits in der Azure Cloud hosten und habe diese via IaC und YAML-Pipeline aufgesetzt. Dabei wurde nach Greenfield & Zero-Trust gearbeitet, um einen hohen Security-Standard einzuhalten!',
        points: ['Kern-Stack in C#.NET, React & Angular (TS)', 'IaC (Bicep / ARM), CI/CD (YAML)', 'OAuth2, Greenfield, Zero-Trust'],
        category: InfoCategory.Dev,
    },
    {
        title: 'Sonstiges',
        description:
            'Verzweifeln Sie nicht an Legacy-Code und migrieren Sie Anwendungen auf einen aktuellen Stand. Gerne unterstütze ich Sie mit meiner Expertise. Komplexe Themen & Prozesse reizen mich besonders!',
        points: ['Legacy-Migrierung, C++, tcl/tk', 'Scrum, Kanban, Agile, Wasserfall', 'Scripting, Java, Matlab'],
        category: InfoCategory.Misc,
    },
    {
        title: 'Kontakt',
        description:
            'Kontaktieren Sie mich gerne, am besten per Mail und wir vereinbaren einen Termin. Gerne beantworte ich Ihre offenen Fragen, wie Stundenlohn, Tech-Stack, Versionserfahrung bei bestimmten Programmiersprachen etc.',
        points: ['Ort: 45468 Mülheim (Ruhr)', 'E-Mail: buss.sven@outlook.com', 'Gerne Kontakt über Xing, LinkedIn'],
        category: InfoCategory.Contact,
    },
]
