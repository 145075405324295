import './index.css'

import * as process from 'process'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') ?? new HTMLElement())
root.render(
    <BrowserRouter basename={process?.env?.PUBLIC_URL ?? undefined}>
        <App />
    </BrowserRouter>
)
