import { format } from 'date-fns'

interface TimelineSubHeaderTimeProps {
    start: Date
    end?: Date
}

const TimelineSubHeaderTime = ({ start, end }: TimelineSubHeaderTimeProps) => {
    return (
        <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            {format(start, 'MM.yyyy')} - {!end ? 'Jetzt' : format(end, 'MM.yyyy')}
        </time>
    )
}

export default TimelineSubHeaderTime
