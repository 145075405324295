import { Button, useDisclosure } from '@nextui-org/react'
import { HoverDampingAnimation } from 'components/atoms/Animation/HoverDampingAnimation'
import SkillSlider from 'components/atoms/Slider/SkillSlider'
import Title from 'components/atoms/Typography/Title'
import SkillSearchModal from 'components/organisms/modals/SkillSearchModal'
import TimelineComponent from 'components/organisms/Timeline/TimelineComponent'
import { mainTechSkills, subTechSkills } from 'models/skills/skillsConstant'
import { timelineEducations, timelineProjects } from 'models/timeline/timelineConstant'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { MdSavedSearch } from 'react-icons/md'
import { TbEaseInOutControlPoints } from 'react-icons/tb'
import { downloadSkillSet } from 'utils/fileHelper'

const SkillResumePage = () => {
    const skillSearchModal = useDisclosure()

    // small workaround to prevent rerender on modal opening
    const [isAnimated, setIsAnimated] = useState<boolean>(false)
    useEffect(() => {
        if (skillSearchModal.isOpen && !isAnimated) setIsAnimated(true)
    }, [skillSearchModal.isOpen])

    return (
        <div className="h-auto w-auto grid px-3 mx-3 lg:px-0 lg:mx-0">
            <Title text="Main-" highlightedText="Skills" hideSpace={true} />
            <div className="lg:flex flex-row gap-5 lg:gap-10">
                <div className="basis-0 lg:basis-1/12" />
                <div className="w-full lg:basis-1/2 flex flex-col gap-3 lg:gap-6">
                    {mainTechSkills.map(techSkill => (
                        <SkillSlider key={nanoid()} skill={techSkill} isAnimated={isAnimated} />
                    ))}
                </div>

                <div className="w-full mt-14 lg:mt-0 lg:basis-1/2 flex flex-col gap-3 lg:gap-6">
                    {subTechSkills.map(subSkill => (
                        <SkillSlider key={nanoid()} skill={subSkill} isAnimated={isAnimated} />
                    ))}
                </div>
            </div>

            <div className="lg:flex gap-4 lg:items-center lg:justify-center mt-8">
                <HoverDampingAnimation>
                    <Button
                        fullWidth
                        color="secondary"
                        variant="shadow"
                        endContent={<TbEaseInOutControlPoints fontSize={20} />}
                        onClick={() => downloadSkillSet()}>
                        Download detailiertes Skill-Set
                    </Button>
                </HoverDampingAnimation>

                <HoverDampingAnimation>
                    <Button
                        fullWidth
                        className="mt-8 lg:mt-0"
                        color="primary"
                        variant="shadow"
                        endContent={<MdSavedSearch fontSize={24} />}
                        onClick={() => skillSearchModal.onOpen()}>
                        Suchen
                    </Button>
                </HoverDampingAnimation>
            </div>

            <div className="mt-8">
                <Title text="Resume /" highlightedText="Projekte" />
            </div>

            <div className="lg:flex flex-row gap-20">
                <div className="basis-1/12" />
                <div className="basis-1/2">
                    <TimelineComponent timelines={timelineEducations} />
                </div>

                <div className="basis-1/2 mt-32 lg:mt-0 ml-0 lg:ml-8">
                    <TimelineComponent timelines={timelineProjects} />
                </div>
            </div>

            <SkillSearchModal disclosure={skillSearchModal} />
        </div>
    )
}

export default SkillResumePage
