import {
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    Pagination,
    Slider,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
} from '@nextui-org/react'
import type { UseDisclosureReturn } from '@nextui-org/use-disclosure'
import { Skill } from 'models/skills/skills'
import { allSkills } from 'models/skills/skillsConstant'
import { useMemo, useState } from 'react'
import { equalsIgnoreCase, includesIgnoreCase } from 'utils/typeHelper'

export default function SkillSearchModal({ disclosure }: { disclosure: UseDisclosureReturn }) {
    const { isOpen, onOpenChange, onClose } = disclosure
    const [actualPage, setActualPage] = useState<number>(1)
    const [searchedSkills, setSearchedSkills] = useState<Skill[]>(allSkills)

    const rowsPerPage = 5

    const skillResult = useMemo(() => {
        const start = (actualPage - 1) * rowsPerPage
        const end = start + rowsPerPage

        const searchedSkillsPerPage = searchedSkills.slice(start, end)
        return {
            pages: Math.ceil(searchedSkills.length / rowsPerPage),
            skills: searchedSkillsPerPage,
        }
    }, [actualPage, searchedSkills])

    const onSearch = (value: string | null) => {
        if (value && value?.length > 0) {
            const searchValues = value.split(/,|&| /)

            const mainSkills = allSkills.filter(s =>
                searchValues.find(value => equalsIgnoreCase(s.name, value) || (s.category && equalsIgnoreCase(s.category, value)))
            )
            const poorSkills = allSkills.filter(s =>
                searchValues.find(value => includesIgnoreCase(s.name, value) || (s.category && includesIgnoreCase(s.category, value)))
            )

            const skills = [...new Set([...mainSkills, ...poorSkills])]

            const pages = Math.round(skills.length / rowsPerPage)
            if (pages < actualPage) setActualPage(1)

            setSearchedSkills(skills)
        } else {
            setSearchedSkills(allSkills)
        }
    }

    return (
        <Modal
            size="xl"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            onClose={() => {
                onSearch(null)
                onClose()
            }}>
            <ModalContent>
                <ModalHeader className="flex flex-col text-pretty dark:text-yellow-600 gap-1">Passenden Skill für ihr Projekt</ModalHeader>
                <ModalBody>
                    <Input
                        isClearable
                        type="text"
                        label="Skillsuche"
                        variant="bordered"
                        onClear={() => onSearch(null)}
                        onChange={text => onSearch(text.currentTarget.value)}
                        className="max-w-xs"
                    />

                    <Table
                        aria-label="Skill table"
                        bottomContent={
                            <div className="flex w-full justify-center">
                                <Pagination
                                    isCompact
                                    showControls
                                    showShadow
                                    color="secondary"
                                    page={actualPage}
                                    total={skillResult.pages}
                                    onChange={page => setActualPage(page)}
                                />
                            </div>
                        }>
                        <TableHeader>
                            <TableColumn key="name">Skill</TableColumn>
                            <TableColumn key="value">Skill-Set</TableColumn>
                        </TableHeader>
                        <TableBody items={skillResult.skills}>
                            {skill => (
                                <TableRow key={skill.name}>
                                    <TableCell width="50%">{skill.name}</TableCell>
                                    <TableCell width="50%">
                                        <Slider
                                            size="sm"
                                            isDisabled={true}
                                            style={{ opacity: 100 }}
                                            color={skill.value > 70 ? 'secondary' : 'primary'}
                                            maxValue={100}
                                            minValue={1}
                                            aria-label={skill.name}
                                            value={skill.value}
                                            className="max-w-md"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
