import { Skill } from './skills'

export const mainTechSkills: Skill[] = [
    { name: 'C#.NET Core', value: 96 },
    { name: 'React (TS)', value: 90 },
    { name: 'Angular (TS)', value: 89 },
    { name: 'Bicep / ARM (IaC)', value: 70 },
    { name: 'YAML (CI/CD)', value: 77 },
]

export const subTechSkills: Skill[] = [
    { name: 'Java', value: 84 },
    { name: 'WPF.NET', value: 65 },
    {
        name: 'Matlab',
        value: 60,
    },
    { name: 'tlc/tk', value: 78 },
    { name: 'Winforms', value: 92 },
]

export const allSkills: Skill[] = [
    {
        name: 'C#',
        value: 100,
        category: 'Microsoft / .NET',
    },
    {
        name: 'VB.NET',
        value: 83,
        category: 'Microsoft / .NET',
    },
    {
        name: 'Blazor',
        value: 33,
        category: 'Microsoft / .NET',
    },
    {
        name: '.NET Core',
        value: 100,
        category: 'Microsoft',
    },
    {
        name: 'WPF.NET',
        value: 66,
        category: 'Microsoft / C#',
    },
    {
        name: 'ASP.NET',
        value: 83,
        category: 'Microsoft / C#',
    },
    {
        name: 'ASP.NET MVC',
        value: 83,
        category: 'Microsoft / C#',
    },
    {
        name: 'ASP.NET Web API',
        value: 100,
        category: 'Microsoft / C#',
    },
    {
        name: 'ASP.NET Web Pages',
        value: 83,
        category: 'Microsoft / C#',
    },
    {
        name: 'ASP.NET Web Forms',
        value: 83,
        category: 'Microsoft / C#',
    },
    {
        name: 'ADO.NET',
        value: 83,
        category: 'Microsoft / C#',
    },
    {
        name: 'Dapper',
        value: 83,
        category: 'Microsoft / .NET',
    },
    {
        name: 'Entity Framework',
        value: 100,
        category: 'Microsoft / .NET',
    },
    {
        name: 'WCF',
        value: 83,
        category: 'Microsoft / .NET',
    },
    {
        name: 'PubSub',
        value: 66,
        category: 'Microsoft / .NET',
    },
    {
        name: 'Json.NET',
        value: 83,
        category: 'Microsoft',
    },
    {
        name: 'MSBuild',
        value: 83,
        category: 'Microsoft / .NET',
    },
    {
        name: 'Jenkins',
        value: 50,
    },
    {
        name: 'NuGet',
        value: 83,
        category: 'Microsoft / .NET',
    },
    {
        name: 'Winforms',
        value: 100,
        category: 'Microsoft / .NET',
    },
    {
        name: 'GraphQL',
        value: 50,
        category: 'Microsoft / .NET / Azure',
    },
    {
        name: 'OData',
        value: 66,
        category: 'Microsoft / .NET / Azure / ASP / API',
    },
    {
        name: 'OpenApi / Swagger',
        value: 83,
        category: 'Microsoft / .NET / Azure / ASP / API',
    },
    {
        name: 'HTML 5',
        value: 83,
        category: 'React / Angular / Blazor / Vue',
    },
    {
        name: 'CSS / SCSS',
        value: 66,
        category: 'React / Angular / Blazor / Vue',
    },
    {
        name: 'Tailwind CSS',
        value: 66,
        category: 'React / Angular / Blazor / Vue',
    },
    {
        name: 'JavaScript',
        value: 83,
        category: 'React / Angular / Blazor / Vue',
    },
    {
        name: 'TypeScript (TS)',
        value: 83,
        category: 'React / Angular / Blazor / Vue',
    },
    {
        name: 'Angular',
        value: 83,
        category: 'HTML / CSS / SCSS / JS / TypeScript',
    },
    {
        name: 'ngx-store',
        value: 50,
        category: 'Angular',
    },
    {
        name: 'ngx-translate',
        value: 66,
        category: 'Angular',
    },
    {
        name: 'ngx-signals',
        value: 50,
        category: 'Angular',
    },
    {
        name: 'Pipes / Interceptor',
        value: 83,
        category: 'Angular',
    },
    {
        name: 'Karma',
        value: 50,
        category: 'Angular',
    },
    {
        name: 'React',
        value: 83,
        category: 'HTML / CSS / SCSS / JS / TypeScript',
    },
    {
        name: 'axios',
        value: 83,
        category: 'React / Angular',
    },
    {
        name: 'testing-libary',
        value: 66,
        category: 'React',
    },
    {
        name: 'Redux',
        value: 66,
        category: 'React',
    },
    {
        name: 'styled-components',
        value: 83,
        category: 'React',
    },
    {
        name: 'emotion',
        value: 50,
        category: 'React',
    },
    {
        name: 'jQuery',
        value: 50,
        category: 'JS',
    },
    {
        name: 'Vue.js',
        value: 16,
        category: 'HTML / CSS / SCSS / JS',
    },
    {
        name: 'Ajax',
        value: 50,
        category: 'JS',
    },
    {
        name: 'Typo3',
        value: 33,
        category: 'React / Angular / Blazor / Vue / JS / TypeScript',
    },
    {
        name: 'node.js',
        value: 63,
        category: 'React / Angular / Blazor / Vue / JS / TypeScript',
    },
    {
        name: 'NGINX',
        value: 50,
        category: 'React / Angular / JS / TypeScript',
    },
    {
        name: 'vite / vittest',
        value: 66,
        category: 'React / Angular / JS / TypeScript',
    },
    {
        name: 'EsLint',
        value: 83,
        category: 'React / Angular / Blazor / Vue / JS / TypeScript',
    },
    {
        name: 'Prettier',
        value: 83,
        category: 'React / Angular / Blazor / Vue / JS / TypeScript',
    },
    {
        name: 'MSAL',
        value: 83,
        category: 'React / Angular / JS / TypeScript',
    },
    {
        name: 'MaterialUI (MUI)',
        value: 100,
        category: 'React / Angular',
    },
    {
        name: 'NextUI',
        value: 50,
        category: 'React',
    },
    {
        name: 'Ionic',
        value: 50,
        category: 'React',
    },
    {
        name: 'Ngx',
        value: 66,
        category: 'Angular',
    },
    {
        name: 'Highcharts',
        value: 66,
        category: 'React / Angular / JS / TypeScript',
    },
    {
        name: 'storybook',
        value: 50,
        category: 'React / Angular',
    },
    {
        name: 'WebComponents',
        value: 50,
        category: 'React / Angular',
    },
    {
        name: 'SignalR',
        value: 50,
        category: 'Angular',
    },
    {
        name: 'MSSQL',
        value: 83,
        category: 'Microsoft',
    },
    {
        name: 'PostgreSQL',
        value: 66,
    },
    {
        name: 'CosmosDB',
        value: 83,
        category: 'Microsoft',
    },
    {
        name: 'SQL-Abfragen',
        value: 100,
    },
    {
        name: 'Oracle',
        value: 50,
        category: 'SQL',
    },
    {
        name: 'MySQL',
        value: 50,
    },
    {
        name: 'SQLite',
        value: 50,
    },
    {
        name: 'MongoDB',
        value: 33,
        category: 'SQL',
    },
    {
        name: 'Datenbankadministration',
        value: 50,
        category: 'Microsoft / SQL',
    },
    {
        name: 'Datenmodellierung',
        value: 83,
        category: 'SQL',
    },
    {
        name: 'Normalisierung',
        value: 83,
        category: 'SQL',
    },
    {
        name: 'Migrationsskripte',
        value: 66,
        category: 'SQL / .NET',
    },
    {
        name: 'Microsoft Azure',
        value: 83,
        category: 'Cloud',
    },
    {
        name: 'KeyVault',
        value: 66,
        category: 'Microsoft / Azure / Cloud',
    },
    {
        name: 'Azure Functions',
        value: 83,
        category: 'Microsoft / Cloud',
    },
    {
        name: 'Storage',
        value: 66,
        category: 'Microsoft / Azure / Cloud',
    },
    {
        name: 'Ressorucen / Apps / App Service Plans',
        value: 66,
        category: 'Microsoft / Azure / Cloud',
    },
    {
        name: 'AppInsights',
        value: 83,
        category: 'Microsoft / Azure / Cloud',
    },
    {
        name: 'Entra ID',
        value: 50,
        category: 'Microsoft / Azure / Cloud',
    },
    {
        name: 'Event-Grid',
        value: 50,
        category: 'Microsoft / Azure / Cloud',
    },
    {
        name: 'APIM',
        value: 66,
        category: 'Microsoft / Azure / Cloud / API',
    },
    {
        name: 'Dashboard / Workbooks',
        value: 50,
        category: 'Microsoft / Azure / Cloud',
    },
    {
        name: 'PowerBI',
        value: 33,
        category: 'Microsoft',
    },
    {
        name: 'Cloud Architektur',
        value: 50,
        category: 'Microsoft / Azure',
    },
    {
        name: 'CI/CD (YAML)',
        value: 83,
        category: 'Microsoft / Azure / GitLab / Piepline',
    },
    {
        name: 'IaaS (Infrastructure as a Service)',
        value: 66,
        category: 'Microsoft / Azure',
    },
    {
        name: 'IaC (Infrastructure as Code)',
        value: 66,
        category: 'Microsoft / Azure / ARM / Bicep / Piepline / CI/CD',
    },
    {
        name: 'ARM',
        value: 50,
        category: 'Microsoft / Azure / Cloud / Piepline / CI/CD',
    },
    {
        name: 'Bicep',
        value: 66,
        category: 'Microsoft / Azure / Cloud / Piepline / CI/CD',
    },
    {
        name: 'DevOps / Automatisierung',
        value: 83,
        category: 'Microsoft / Azure / Cloud / Piepline / CI/CD',
    },
    {
        name: 'TFS (Team Foundation Server)',
        value: 33,
        category: 'Microsoft / .NET',
    },
    {
        name: 'Container-Orchestrierung',
        value: 50,
        category: 'Microsoft / Azure / Cloud',
    },
    {
        name: 'Docker',
        value: 66,
        category: 'Microsoft / Azure / Cloud',
    },
    {
        name: 'Kubernetes',
        value: 33,
        category: 'Microsoft / Azure / Cloud',
    },
    {
        name: 'Cloud-Networking',
        value: 50,
        category: 'Microsoft / Azure',
    },
    {
        name: 'Cloud-Integration',
        value: 66,
        category: 'Microsoft / Azure / Pipeline / CI/CD',
    },
    {
        name: 'Helm',
        value: 16,
        category: 'Microsoft / Azure / Cloud',
    },
    {
        name: 'On-Premise Lösungen',
        value: 66,
        category: 'Microsoft',
    },
    {
        name: 'Backend',
        value: 100,
        category: 'C# / VB / .NET / JAVA / ASP',
    },
    {
        name: 'Frontend',
        value: 100,
        category: 'Angular / React / Vue / Blazor / WPF / Winforms / HTML / CSS / SCSS / JS / TypeScript',
    },
    {
        name: 'Fullstack',
        value: 100,
        category: 'Angular / React / Vue / Blazor / WPF / Winforms / C# / JAVA / .NET / Cloud',
    },
    {
        name: 'Clean Code',
        value: 100,
        category: 'C# / VB / .NET / JAVA / TypeScript',
    },
    {
        name: 'Test Driven Development (TDD)',
        value: 83,
        category: 'C# / VB / .NET / JAVA / TypeScript',
    },
    {
        name: 'OOP',
        value: 100,
        category: 'C# / VB / .NET / JAVA / TypeScript / Matlab',
    },
    {
        name: 'OOD',
        value: 66,
    },
    {
        name: 'MVC',
        value: 83,
        category: 'C# / JAVA / WPF / .NET',
    },
    {
        name: 'MVVM',
        value: 66,
        category: 'C# / JAVA / WPF / .NET',
    },
    {
        name: 'MVP',
        value: 83,
    },
    {
        name: 'Microservices',
        value: 83,
        category: 'C# / .NET / Microsoft',
    },
    {
        name: 'Monolith-Architektur',
        value: 50,
    },
    {
        name: 'Design Patterns',
        value: 83,
        category: 'C# / JAVA / .NET / Architektur',
    },
    {
        name: 'REST',
        value: 100,
        category: 'API',
    },
    {
        name: 'SOAP',
        value: 83,
        category: 'API',
    },
    {
        name: 'jUnit',
        value: 66,
        category: 'C# / .NET / testing',
    },
    {
        name: 'xUnit',
        value: 100,
        category: 'C# / .NET / testing',
    },
    {
        name: 'Mock / FakeItEasy',
        value: 83,
        category: 'C# / .NET / testing',
    },
    {
        name: 'Mockito / PowerMock',
        value: 50,
        category: 'JAVA / testing',
    },
    {
        name: 'Testautomatisierung',
        value: 33,
        category: 'C# / .NET / JAVA',
    },
    {
        name: 'Web-Testing',
        value: 66,
        category: 'React / Angular / TypeScript',
    },
    {
        name: 'Performance Testing',
        value: 66,
        category: 'C# / .NET / JAVA',
    },
    {
        name: 'Integration Testing',
        value: 83,
        category: 'C# / .NET / JAVA',
    },
    {
        name: 'DSGVO',
        value: 66,
    },
    {
        name: 'ISO 27001',
        value: 33,
    },
    {
        name: 'Security Architektur',
        value: 50,
    },
    {
        name: 'Virtual Network',
        value: 50,
        category: 'Azure / Cloud',
    },
    {
        name: 'Verschlüsselung / PKI',
        value: 33,
        category: 'Security',
    },
    {
        name: 'OpenSSL',
        value: 33,
        category: 'Security',
    },
    {
        name: 'Oauth 2 / Bearer-Token / JWT',
        value: 83,
        category: 'Azure / Cloud / Security',
    },
    {
        name: 'Identity Server',
        value: 63,
        category: 'Azure / Cloud / Security',
    },
    {
        name: 'CORS',
        value: 66,
        category: 'Azure / Cloud / Security',
    },
    {
        name: 'Endpoint Security',
        value: 66,
        category: 'Azure / Cloud / Security / API',
    },
    {
        name: 'Cloud Security',
        value: 50,
        category: 'Azure',
    },
    {
        name: 'Code-Review-Tools',
        value: 100,
        category: 'Azure / DevOps / Bitbucket / GitLab',
    },
    {
        name: 'Security Compliance',
        value: 50,
        category: 'Cloud',
    },
    {
        name: 'VPN',
        value: 66,
        category: 'Security',
    },
    {
        name: 'Scrum',
        value: 66,
    },
    {
        name: 'Kanban',
        value: 66,
    },
    {
        name: 'Agile Working',
        value: 83,
    },
    {
        name: 'Confluence',
        value: 33,
    },
    {
        name: 'Jira',
        value: 50,
    },
    {
        name: 'Git',
        value: 83,
    },
    {
        name: 'GitLab',
        value: 67,
    },
    {
        name: 'GitHub',
        value: 50,
    },
    {
        name: 'SVN',
        value: 67,
    },
    {
        name: 'Bitbucket',
        value: 16,
    },
    {
        name: 'Anforderungsmanagement',
        value: 66,
    },
    {
        name: 'UML',
        value: 50,
    },
    {
        name: 'Projektplanung',
        value: 66,
    },
    {
        name: 'SonarQube / SonarLint',
        value: 83,
    },
    {
        name: 'NexusIQ',
        value: 66,
    },
    {
        name: 'Java',
        value: 83,
    },
    {
        name: 'Spring Core',
        value: 33,
        category: 'Java',
    },
    {
        name: 'Spring Boot',
        value: 50,
        category: 'Java',
    },
    {
        name: 'Java EE',
        value: 83,
    },
    {
        name: 'Kotlin (Android / iOS)',
        value: 33,
    },
    {
        name: 'Andoroid (Java)',
        value: 66,
    },
    {
        name: 'iOS (Objective-C)',
        value: 50,
    },
    {
        name: 'PowerShell',
        value: 83,
        category: 'Script',
    },
    {
        name: 'Bash',
        value: 66,
        category: 'Script',
    },
    {
        name: 'TCL/TK',
        value: 83,
        category: 'Script',
    },
    {
        name: 'Matlab',
        value: 50,
        category: 'Script',
    },
    {
        name: 'GO',
        value: 33,
        category: 'Script',
    },
    {
        name: 'Python',
        value: 16,
        category: 'Script',
    },
    {
        name: 'Visual Studio',
        value: 100,
        category: 'Tooling',
    },
    {
        name: 'Jetbrains Rider',
        value: 83,
        category: 'Tooling',
    },
    {
        name: 'Jetbrains DataGrip',
        value: 66,
        category: 'Tooling',
    },
    {
        name: 'Jetbrains Resharper',
        value: 100,
        category: 'Tooling',
    },
    {
        name: 'VS Code',
        value: 100,
        category: 'Tooling',
    },
    {
        name: 'Chrome DevTools',
        value: 83,
        category: 'Tooling',
    },
    {
        name: 'SQL Management Studio',
        value: 83,
        category: 'Tooling',
    },
    {
        name: 'Azure Data Studio',
        value: 66,
        category: 'Tooling',
    },
    {
        name: 'Git Graph',
        value: 83,
        category: 'Tooling',
    },
    {
        name: 'Sourcetree',
        value: 83,
        category: 'Tooling',
    },
    {
        name: 'Netbeans',
        value: 83,
        category: 'Tooling',
    },
    {
        name: 'Eclipse',
        value: 66,
        category: 'Tooling',
    },
    {
        name: 'Android Studio',
        value: 66,
        category: 'Tooling',
    },
    {
        name: 'XCode',
        value: 50,
        category: 'Tooling',
    },
    {
        name: 'Sublime Text',
        value: 66,
        category: 'Tooling',
    },
    {
        name: 'Postman',
        value: 83,
        category: 'Tooling',
    },
    {
        name: 'Outlook',
        value: 83,
        category: 'Tooling',
    },
    {
        name: 'O365 (Office 365)',
        value: 83,
        category: 'Microsoft / Word / Excel',
    },
    {
        name: 'MS Teams',
        value: 83,
        category: 'Tooling',
    },
    {
        name: 'Slack',
        value: 66,
        category: 'Tooling',
    },
    {
        name: 'Windows',
        value: 83,
        category: 'Betriebssysteme',
    },
    {
        name: 'MacOS',
        value: 66,
        category: 'Betriebssysteme',
    },
    {
        name: 'Linux',
        value: 66,
        category: 'Betriebssysteme',
    },
    {
        name: 'Deutsch',
        value: 100,
        category: 'Sprachen',
    },
    {
        name: 'Busniess Englisch',
        value: 66,
        category: 'Sprachen',
    },
    {
        name: 'Englisch',
        value: 50,
        category: 'Sprachen',
    },
]
