import { Avatar, Button, useDisclosure } from '@nextui-org/react'
import { HoverDampingAnimation } from 'components/atoms/Animation/HoverDampingAnimation'
import { SlideInAnimation } from 'components/atoms/Animation/SlideInAnimation'
import Footer from 'components/molecules/Footer'
import PlatformContact from 'components/molecules/PlatformContact'
import SkillSearchModal from 'components/organisms/modals/SkillSearchModal'
import { motion } from 'framer-motion'
import { PortraitImage } from 'images/portraitImage'
import { GiSkills } from 'react-icons/gi'
import { MdSavedSearch } from 'react-icons/md'
import { downloadSelfInformationZipFile } from 'utils/fileHelper'
import { variantVisible } from 'utils/framerMotionConstants'

const buttonClass = 'h-9 lg:h-8 my-2 lg:my-0'
const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.2,
            staggerChildren: 0.2,
        },
    },
}

const HomePage = () => {
    const skillSearchModal = useDisclosure()

    return (
        <>
            <div className="h-auto w-auto grid px-0 mx-0">
                <div className="lg:flex lg:items-center lg:justify-center lg:h-[86.5dvh]">
                    <div className="flex lg:flex-row justify-center py-14 lg:py-0">
                        <SlideInAnimation>
                            <Avatar isBordered src={`data:image/png;base64,${PortraitImage}`} className="w-40 h-40 lg:w-72 lg:h-72" />
                        </SlideInAnimation>

                        <motion.div className="lg:absolute grid lg:mt-80" variants={variantVisible}>
                            <PlatformContact />
                        </motion.div>
                    </div>

                    <motion.ul className="grid gap-3 mx-4 lg:mx-8 flex-2" variants={container} initial="hidden" animate="visible">
                        <motion.h1
                            className="text-3xl lg:text-6xl text-center lg:text-left font-extrabold dark:text-white"
                            variants={variantVisible}>
                            Hi, ich bin Sven Buss
                        </motion.h1>

                        <motion.h2
                            className="text-pretty text-center lg:text-left text-small lg:text-xl font-extralight"
                            variants={variantVisible}>
                            Als Fullstack-Software-Engineer verfolge ich seit Beginn meinen eigenen Berufstraum! C#.NET, React & Angular,
                            Azure DevOps - Dort fühle ich mich heimisch.
                        </motion.h2>

                        <motion.div className="text-small lg:flex text-center items-center mt-2 lg:mt-6 gap-6" variants={variantVisible}>
                            <span className="font-extralight">Finden Sie die passenden Skills für ihr Projekt:</span>

                            <HoverDampingAnimation>
                                <Button
                                    className={buttonClass}
                                    color="primary"
                                    variant="shadow"
                                    endContent={<MdSavedSearch fontSize={24} />}
                                    onClick={() => skillSearchModal.onOpen()}>
                                    Suchen
                                </Button>
                            </HoverDampingAnimation>

                            <HoverDampingAnimation>
                                <Button
                                    className={buttonClass}
                                    color="secondary"
                                    variant="shadow"
                                    endContent={<GiSkills fontSize={24} />}
                                    onClick={() => downloadSelfInformationZipFile()}>
                                    Download CV + Referenzen
                                </Button>
                            </HoverDampingAnimation>
                        </motion.div>
                    </motion.ul>
                </div>

                <SkillSearchModal disclosure={skillSearchModal} />
            </div>

            <div className="lg:mt-0 lg:relative absolute inset-x-0 bottom-0 h-20">
                <Footer />
            </div>
        </>
    )
}

export default HomePage
