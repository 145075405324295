import { SlideInAnimation } from 'components/atoms/Animation/SlideInAnimation'
import { Info } from 'models/info/info'
import { InfoCategory } from 'models/info/infoCategory'
import { nanoid } from 'nanoid'
import { ReactElement } from 'react'
import { FaHeadSideVirus, FaPersonRays } from 'react-icons/fa6'
import { GrContactInfo } from 'react-icons/gr'
import { VscSymbolMisc, VscWorkspaceUnknown } from 'react-icons/vsc'

interface InfoCardProps {
    info: Info
    isRTL?: boolean
}

const imageClass = 'text-5xl rounded-full border-large border-y-0 border-x-0 text-yellow-600'

function mapIcon(category: InfoCategory): ReactElement {
    switch (category) {
        case InfoCategory.Me:
            return <FaPersonRays />
        case InfoCategory.Dev:
            return <FaHeadSideVirus />
        case InfoCategory.Misc:
            return <VscSymbolMisc />
        case InfoCategory.Contact:
            return <GrContactInfo />
        default:
            return <VscWorkspaceUnknown />
    }
}

const InfoCard = (props: InfoCardProps) => {
    const roundedClass = props.isRTL ? 'rounded-s-xl rounded-br-xl' : 'rounded-e-xl rounded-es-xl'

    return (
        <SlideInAnimation className="flex items-start gap-2.5 mx-4 lg:mx-0" scale={1.3}>
            {!props.isRTL && <div className={imageClass}>{mapIcon(props.info.category)}</div>}

            <div
                className={`flex flex-col w-full h-68 lg:h-60 leading-1.5 p-4 border-gray-200 bg-gray-100 dark:bg-gray-800 ${roundedClass}`}>
                <div className="flex items-center space-x-2 rtl:space-x-reverse">
                    <h1 className="text-lg lg:text-xl font-semibold text-gray-900 dark:text-yellow-600">{props.info.title}</h1>
                </div>
                <p className="h-full font-normal text-small lg:text-sm py-2.5 text-gray-900 dark:text-white">{props.info.description}</p>

                <ul className="space-y-1 list-disc list-inside text-xs lg:text-sm text-default-500">
                    {props.info.points.map(p => (
                        <li key={nanoid()}>{p}</li>
                    ))}
                </ul>
            </div>

            {props.isRTL && <div className={imageClass}>{mapIcon(props.info.category)}</div>}
        </SlideInAnimation>
    )
}

export default InfoCard
