import { ReactNode } from 'react'

export interface WithChildren {
    children: NonNullable<ReactNode>
}

export function equalsIgnoreCase(element: string | null, value: string | null) {
    if (!value || !element) return false
    return element.toLocaleLowerCase().trim() === value.toLocaleLowerCase().trim()
}

export function includesIgnoreCase(element: string | null, value: string | null) {
    if (!value || !element) return false
    return element.toLocaleLowerCase().trim().includes(value.toLocaleLowerCase().trim())
}
