import { Modal, useDisclosure } from '@nextui-org/react'
import Title from 'components/atoms/Typography/Title'
import InfoCard from 'components/organisms/Card/InfoCard'
import ServiceCard from 'components/organisms/Card/ServiceCard'
import SkillModal from 'components/organisms/modals/SkillModal'
import { motion } from 'framer-motion'
import { infoConstants } from 'models/info/infoConstants'
import { Service } from 'models/service/service'
import { serviceConstants } from 'models/service/serviceConstant'
import { nanoid } from 'nanoid'
import { useState } from 'react'

const AboutMePage = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [selectedService, setSelectedService] = useState<Service>()
    const [isAnimated, setIsAnimated] = useState<boolean>(false)

    return (
        <div className="h-auto w-auto grid">
            <Title text="About" highlightedText="Me" />

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mb-10">
                {infoConstants.map((info, i) => (
                    <InfoCard key={nanoid()} info={info} isRTL={i % 2 == 1} />
                ))}
            </div>

            <Title text="Aktuelle" highlightedText="Referenzen" />

            <div className="gap-4 grid grid-cols-1 lg:grid-cols-3 mx-2 mb-16 lg:mb-32">
                {serviceConstants.map(skill => (
                    <motion.div
                        key={nanoid()}
                        initial={{ opacity: isAnimated ? 1 : 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ delay: 0.5, duration: 1.2 }}
                        viewport={{ once: true, amount: 0.1 }}
                        onAnimationComplete={() => setIsAnimated(true)}>
                        <ServiceCard
                            key={nanoid()}
                            service={skill}
                            onCardClick={(service: Service) => {
                                setSelectedService(service)
                                onOpen()
                            }}
                        />
                    </motion.div>
                ))}
            </div>

            <Modal isOpen={isOpen} onClose={onClose}>
                {selectedService && <SkillModal service={selectedService} />}
            </Modal>
        </div>
    )
}

export default AboutMePage
