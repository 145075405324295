import { motion } from 'framer-motion'
import { ReactNode } from 'react'

interface SlideInAnimationProps {
    children: NonNullable<ReactNode>
    className?: string
    scale?: number
}

export const SlideInAnimation = (props: SlideInAnimationProps) => {
    return (
        <motion.div
            className={props.className}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
                duration: 0.5,
            }}
            initial={{ opacity: 0, scale: 0.2 }}
            whileHover={{ scale: props.scale ? props.scale : 1.2 }}>
            {props.children}
        </motion.div>
    )
}
