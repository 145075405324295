import './index.css'

import { NextUIProvider } from '@nextui-org/react'
import Header from 'components/molecules/Header'
import AboutMePage from 'pages/AboutMePage'
import HomePage from 'pages/HomePage'
import PrivatePage from 'pages/PrivatePage'
import SkillResumePage from 'pages/SkillsResumePage'
import { Route, Routes, useNavigate } from 'react-router-dom'

function App() {
    const navigate = useNavigate()

    return (
        <NextUIProvider navigate={navigate}>
            <main className="dark container max-w-xl lg:max-w-7xl lg:mx-auto">
                <Header />

                <Routes>
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/about-me" element={<AboutMePage />} />
                    <Route path="/skills-resume" element={<SkillResumePage />} />
                    <Route path="/private" element={<PrivatePage />} />
                    <Route path="/*" element={<HomePage />} />
                </Routes>
            </main>
        </NextUIProvider>
    )
}

export default App
