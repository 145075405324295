import { Card, CardBody, CardFooter, CardHeader, Tooltip } from '@nextui-org/react'
import { Service } from 'models/service/service'
import { ServiceCategory } from 'models/service/serviceCategory'
import { ReactElement } from 'react'
import { BsMotherboard } from 'react-icons/bs'
import { FaReact } from 'react-icons/fa'
import { FaCloudMeatball } from 'react-icons/fa6'
import { GiTeamUpgrade } from 'react-icons/gi'
import { SiAzurepipelines, SiMicrosoftsqlserver } from 'react-icons/si'
import { VscWorkspaceUnknown } from 'react-icons/vsc'

interface ServiceCardProps {
    service: Service
    onCardClick: (selectedService: Service) => void
}

function mapIcon(category: ServiceCategory): ReactElement {
    switch (category) {
        case ServiceCategory.Frontend:
            return <FaReact />
        case ServiceCategory.Backend:
            return <SiMicrosoftsqlserver />
        case ServiceCategory.Cloud:
            return <FaCloudMeatball />
        case ServiceCategory.Engineering:
            return <SiAzurepipelines />
        case ServiceCategory.Management:
            return <GiTeamUpgrade />
        case ServiceCategory.Misc:
            return <BsMotherboard />
        default:
            return <VscWorkspaceUnknown />
    }
}

const ServiceCard = ({ service, onCardClick }: ServiceCardProps) => {
    return (
        <Tooltip showArrow={true} content="Projektreferenzen aufrufen (Klick)">
            <Card
                isBlurred
                style={{ width: '100%', height: '100%' }}
                isPressable
                shadow="sm"
                className="dark:bg-content1"
                onClick={() => onCardClick(service)}>
                <CardHeader>
                    <div className="flex flex-col items-center justify-center w-full gap-6">
                        <div className="text-6xl">{mapIcon(service.category)}</div>
                        <h3 className="uppercase dark:text-yellow-600">{service.name}</h3>
                    </div>
                </CardHeader>

                <CardBody className="gap-2 text-center">
                    <p className="text-pretty">{service.description}</p>
                    <p className="text-tiny">{service.subDescription}</p>
                </CardBody>

                <CardFooter className="grid grid-cols-2">
                    <p className="font-semibold text-default-400 text-small">Erfahrung: {service.yearsOfExperience}y+</p>
                    <p className="font-semibold text-default-400 text-small place-content-end">Projekte: {service.countOfProjects}+</p>
                </CardFooter>
            </Card>
        </Tooltip>
    )
}

export default ServiceCard
