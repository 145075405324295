import { Accordion, AccordionItem, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react'
import { Service } from 'models/service/service'
import { nanoid } from 'nanoid'

interface SkillModalProps {
    service: Service
}

const SkillModal = ({ service }: SkillModalProps) => {
    return (
        <ModalContent>
            <ModalHeader className="flex flex-col text-pretty text-yellow-600 gap-1">Aktuellste Referenzen für {service.name}</ModalHeader>
            <ModalBody>
                <ul className="list-inside">
                    {service.projects.map((proj, i) => (
                        <Accordion key={nanoid()} selectionMode="single" defaultExpandedKeys={['project-0']}>
                            <AccordionItem key={`project-${i}`} aria-label={proj.name} title={proj.name}>
                                <ul className="space-y-1 list-disc list-inside text-default-400">
                                    {proj.skills.map(skill => (
                                        <li key={nanoid()}>{skill}</li>
                                    ))}
                                </ul>

                                <ul className="my-1 space-y-1 list-disc list-inside text-default-400">
                                    {proj.tasks.map(task => (
                                        <li key={nanoid()}>{task}</li>
                                    ))}
                                </ul>
                            </AccordionItem>
                        </Accordion>
                    ))}
                </ul>
            </ModalBody>
        </ModalContent>
    )
}

export default SkillModal
