import { Tooltip } from '@nextui-org/react'
import { PrivateDetail } from 'models/private/privateDetail'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

interface ImageZoomProps {
    detail: PrivateDetail
    showInitialTooltip?: boolean
}

const textClassNameNormal = `absolute w-full py-2.5 bottom-0 text-xs lg:text-small text-center
    bg-blue-500 bg-opacity-85 text-white opacity-0`
const textClassNameZoom = textClassNameNormal + 'hover:opacity-100 duration-400'

const ImageZoom = (props: ImageZoomProps) => {
    const [isImageHover, setIsImageHover] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)

    useEffect(() => {
        if (props.showInitialTooltip) {
            showToolTipDebounced()
        }
    }, [props.showInitialTooltip])

    const showToolTipDebounced = useDebouncedCallback(() => {
        setShowTooltip(true)
        closeToolTipDebounced()
    }, 400)
    const closeToolTipDebounced = useDebouncedCallback(() => setShowTooltip(false), 4000)

    return (
        <div className="relative overflow-hidden h-full w-full">
            <Tooltip
                color="secondary"
                isOpen={showTooltip}
                isDisabled={!showTooltip}
                showArrow={true}
                content="Fahren Sie über die Bilder um mehr zu erfahren">
                <img
                    className="object-fill h-full w-full rounded-lg hover:scale-125 transition-all duration-500"
                    onMouseOver={() => setIsImageHover(true)}
                    onMouseLeave={() => setIsImageHover(false)}
                    src={`data:image/png;base64, ${props.detail.imageBase64}`}
                />
            </Tooltip>

            <div className={isImageHover ? textClassNameZoom : textClassNameNormal} onClick={() => setIsImageHover(!isImageHover)}>
                <p className="font-bold">{props.detail.textFooter}</p>
                {props.detail.textSubFooter && <p>{props.detail.textSubFooter}</p>}
            </div>
        </div>
    )
}

export default ImageZoom
