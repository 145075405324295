import { SlideInTextAnimation } from '../Animation/SlideInTextAnimation'

interface TitleProps {
    text: string
    highlightedText: string
    hideSpace?: boolean
}

const Title = (props: TitleProps) => {
    return (
        <div className="flex items-center justify-center my-12 lg:my-24">
            <hr className="w-full lg:w-64 h-1 my-8 mx-4 bg-gray-200 border-0 rounded dark:bg-gray-700" />
            <SlideInTextAnimation text={props.text} className="uppercase text-center text-2xl lg:text-3xl" />

            {!props.hideSpace && <p className="text-2xl lg:text-3xl">&nbsp;</p>}

            <SlideInTextAnimation
                text={props.highlightedText}
                className="uppercase text-center text-2xl lg:text-3xl dark:text-yellow-600"
            />
            <hr className="w-full lg:w-64 h-1 my-8 mx-4 bg-gray-200 border-0 rounded dark:bg-gray-700" />
        </div>
    )
}

export default Title
