import { motion } from 'framer-motion'
import { WithChildren } from 'utils/typeHelper'

export const HoverDampingAnimation = (props: WithChildren) => {
    return (
        <motion.div whileHover={{ scale: 1.1 }} transition={{ type: 'spring', stiffness: 400, damping: 10 }}>
            {props.children}
        </motion.div>
    )
}
