import { Slider } from '@nextui-org/react'
import { Skill } from 'models/skills/skills'
import { memo, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

interface SliderProps {
    skill: Skill
    isAnimated: boolean
}

export default memo(function SkillSlider(props: SliderProps) {
    const [value, setValue] = useState<number>(props.isAnimated ? props.skill.value : 1)
    const setValueDebounced = useDebouncedCallback(val => setValue(val), 10)

    useEffect(() => {
        if (value && value < props.skill.value) {
            setValueDebounced(value + 2)
        }
    }, [value])

    return (
        <Slider
            size="sm"
            isDisabled={true}
            style={{ opacity: 100 }}
            color={props.skill.value > 70 ? 'secondary' : 'primary'}
            maxValue={100}
            minValue={1}
            label={props.skill.name}
            value={value}
            className="max-w-md"
        />
    )
})
